import * as React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Cta from "../components/cta";
import * as Styles from "../styles/effect.module.css";

const AboutUs = () => {
  return (
    <Layout pageTitle="Home Page">
      {/* MetaTag */}
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>
          About Us - KATSANA® Integrated Fleet Management & Industrial Operation
          Solutions
        </title>
        <meta
          name="description"
          content="We are transforming fleet & enterprise operations for the digital age."
        />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://www.katsana.com/about-us/" />

        {/* Open Graph */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.katsana.com/about-us/" />
        <meta
          property="og:title"
          content="About Us - KATSANA® Integrated Fleet Management & Industrial Operation Solutions"
        />
        <meta
          property="og:description"
          content="We are transforming fleet & enterprise operations for the digital age."
        />
        <meta
          property="og:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
        <meta
          property="og:image:alt"
          content="KATSANA Fleet Management & Industrial IoT Sensors Expert"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="640" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@katsanagps" />
        <meta name="twitter:creator" content="@katsanagps" />

        {/* image must be 2:1 max 5mb */}
        <meta
          name="twitter:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />

        <script type="application/ld+json">
          {`
            { "@context" : "https://schema.org",
            "@type" : "LocalBusiness",
            "url" : "https://www.katsana.com",
            "logo" : "https://www.katsana.com/katsana-logo.jpg",  
            "name" : "KATSANA Advanced Telematics",    
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Kuala Lumpur",
            "addressRegion": "WP",
              "postalCode": "60000",
              "addressCountry": "MY",	
              "streetAddress": "First Floor, Lot 2805, Jalan Damansara"
              },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "bestRating": "5",
              "ratingCount": "340"
            },	
            "geo": {
                  "@type": "GeoCoordinates",
                  "latitude": 3.1619359,
                  "longitude": 101.6179103
              },
            "telephone": "+60377334474",
            "contactPoint" : [
              { "@type" : "ContactPoint",
                "telephone" : "+60377334474",
                "contactType" : "Office Contact"
              } , { "@type" : "ContactPoint",
                "telephone" : "+60193960127",
                "contactType" : "Sales Enquiry #1"
              } ] }
          `}
        </script>
      </Helmet>
      {/* Hero Area */}
      <div className="relative p-8 pt-12 pb-24 overflow-hidden xl:px-0 md:pt-32 md:pb-48">
        <StaticImage
          alt="katsana"
          src="../../static/images/photos/aboutus-hero.jpg"
          objectFit="cover"
          className="!absolute inset-0"
        />

        <div className="relative z-10 mx-auto max-w-primary xl:px-20">
          <h3 className="pt-24 text-lg md:pt-12 md:text-2xl text-bluegray">
            About Katsana
          </h3>
          <h1 className="pt-4 text-3xl font-bold tracking-wide text-white md:leading-tight md:mt-0 md:text-6xl">
            We are transforming{" "}
            <span class="border-b-4">fleet & enterprise operations</span> for
            the digital age.
          </h1>
        </div>
      </div>

      <div className=" bg-[hsla(204,26%,93%,1)] relative pb-36">
        <div className="absolute top-0 left-0 w-full">
          <div
            className={
              Styles.tilt +
              " after:bg-[hsla(204,26%,93%,1)] z-10 m-[-1px] after:h-[4vh] md:after:h-[10vh] z-10 "
            }
          ></div>
        </div>
        <div className="px-8 py-12 mx-auto max-w-primary xl:px-0">
          <StaticImage
            alt="katsana"
            src="../../static/images/photos/aboutus-team.jpg"
            objectFit="contain"
            className="w-full"
          />
        </div>
        <div className="max-w-6xl px-8 mx-auto md:py-12 xl:px-0">
          <div className="flex flex-col justify-between md:flex-row">
            <div className="md:w-2/5">
              <h3 className="text-3xl font-bold text-blue-700">
                It all started with
                <br /> a simple idea...
              </h3>
            </div>
            <div className="mt-6 text-lg md:w-3/5 text-bluetext md:mt-0">
              <p className="mb-4">
                In 2013, two cars belonging to people close to the founder of
                KATSANA, Syed Ahmad Fuqaha were stolen in the span of just a
                week.
              </p>
              <p className="mb-4">
                His market researched showed that existing solutions to track
                and secure vehicles were outdated and did not take advantage of
                rapid smartphone adoption that would allow vehicle owners to
                track vehicles in real-time.
              </p>

              <p className="mb-4">
                Together with co-founder Irwan Ibrahim, the duo decided to bring
                innovations into the vehicle telematics/tracking market.
              </p>

              <p className="mb-4">
                From what started as a simple idea to track and secure cars,
                KATSANA diversified into fleet management, telematics for
                usage-based insurance, big-data for automotive and industrial
                IoT (internet-of-things) technologies.
              </p>
              <p className="mb-4">
                Today, KATSANA is one of the fastest growing tech companies in
                Malaysia with footprints across Asia, Australia and South
                Americas.
              </p>
            </div>
          </div>
        </div>
        <div className="max-w-5xl px-8 py-12 mx-auto xl:px-0">
          <div className="flex flex-col justify-around md:flex-row">
            <div className="md:text-center ">
              <h3 className="text-4xl font-bold text-blue-700">April 2014</h3>
              <p className="text-gray-400">Date founded</p>
            </div>
            <div className="mt-6 md:mt-0 md:text-center">
              <h3 className="text-4xl font-bold text-blue-700">50+</h3>
              <p className="text-gray-400">
                Employees in
                <br /> Malaysia & Indonesia
              </p>
            </div>
            <div className="mt-6 md:mt-0 md:text-center ">
              <h3 className="text-4xl font-bold text-blue-700">2.3+ Billion</h3>
              <p className="text-gray-400">
                kilometers of vehicle movement
                <br /> data annually
              </p>
            </div>
            <div className="mt-6 md:mt-0 md:text-center">
              <h3 className="text-4xl font-bold text-blue-700">3600+</h3>
              <p className="text-gray-400">
                companies served
                <br /> around the globe
              </p>
            </div>
          </div>
        </div>
        <div className="max-w-4xl px-8 py-6 mx-auto md:py-12 xl:px-0">
          <div className="grid grid-cols-1 gap-12 md:grid-cols-2">
            <div className="col-span-1 px-8 py-12 bg-white rounded">
              <div className="flex items-end h-12">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/logos/katsana.png"
                  objectFit="contain"
                  className="w-full "
                />
              </div>
              <ul className="mt-8 tracking-wide text-center md:text-lg text-bluetext">
                <li>Vehicle Telematics / Location Tracking</li>
                <li>Fleet Management Solutions</li>
                <li>Integrated Vehicle Operations</li>
                <li>Integrated Industrial Operations</li>
              </ul>
            </div>
            <div className="col-span-1 p-12 bg-white rounded">
              <div className="h-12 ">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/logos/drivemark.png"
                  objectFit="contain"
                  className="w-full"
                />
              </div>
              <ul className="mt-8 tracking-wide text-center md:text-lg text-bluetext">
                <li>Mobile Telematics</li>
                <li>Driver Behavior & Scoring</li>
                <li>Usage-based Insurance</li>
                <li>Loyalty & Rewards for Safe Drivers</li>
              </ul>
            </div>
          </div>
        </div>

        <div
          id="investors"
          className="justify-between max-w-6xl px-8 py-12 mx-auto md:mt-12 xl:px-0"
        >
          <div className="flex flex-col md:flex-row">
            <div className="md:w-2/5">
              <h3 className="text-3xl font-bold text-blue-700 xl:text-4xl">
                Growth backed by
              </h3>
            </div>
            <div className="mt-6 md:w-3/5 md:mt-0">
              <StaticImage
                alt="katsana"
                src="../../static/images/logos/backedby.jpg"
                objectFit="cover"
                className="md:-mt-8"
              />
              <p className="mt-8 text-lg text-bluetext">
                KATSANA has raised MYR6.5 mil in equity and MYR 3.0 mil in
                grants and venture debts. We are thankful to be supported by
                venture investors, corporates and startup programmes that
                believe in our mission.
              </p>
            </div>
          </div>
        </div>
        <div className="justify-between max-w-6xl px-8 py-12 mx-auto md:mt-12 xl:px-0">
          <div className="flex flex-col md:flex-row">
            <div className="md:w-2/5">
              <h3 className="text-3xl font-bold text-blue-700 xl:text-4xl">
                Awards & <br />
                Recognitions
              </h3>
            </div>
            <div className="mt-6 md:text-lg md:w-3/5 text-bluetext md:mt-0">
              <div>
                <h5 className="font-medium">2014 Winner</h5>
                <p>
                  TERAJU Skim Usahawan Permulaan Bumiputera (SUPERB) Series 1
                </p>
              </div>
              <div className="mt-4">
                <h5 className="font-medium">2015 Finalist</h5>
                <p>MaGIC Accelerator Programme, Cyberjaya</p>
              </div>
              <div className="mt-4">
                <h5 className="font-medium">2016 Finalist</h5>
                <p>Alliance Bank SME Innovation Challenge, Kuala Lumpur</p>
              </div>
              <div className="mt-4">
                <h5 className="font-medium">2017 Finalist</h5>
                <p>
                  Nissan Infiniti Smart Mobility Program, Hong Kong & Singapore
                </p>
              </div>
              <div className="mt-4">
                <h5 className="font-medium">2017 Malaysia Representative</h5>
                <p>Asian Enterpreneurship Award, Japan</p>
              </div>
              <div className="mt-4">
                <h5 className="font-medium">2018 Finalist</h5>
                <p>22nd Asia Insurance Industry Awards, Singapore</p>
              </div>
              <div className="mt-4">
                <h5 className="font-medium">2018 IDC Financial Insights</h5>
                <p>10 Fastest Growing Fintech Companies in Malaysia</p>
              </div>
              <div className="mt-4">
                <h5 className="font-medium">2019 Finalist</h5>
                <p>SMMT Future Mobility Challenge, London</p>
              </div>
              <div className="mt-4">
                <h5 className="font-medium">2020 Fintech News Malaysia</h5>
                <p>Top 20 Malaysian Fintech in 2020</p>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-6xl px-8 pt-12 mx-auto xl:px-0 pb-36">
          <h3 className="text-3xl font-bold text-blue-700 md:text-4xl">
            Press
          </h3>
          <div className="grid grid-cols-2 col-span-2 gap-6 mt-6 text-lg font-medium md:gap-12 md:mt-16 xl:gap-16 xl:text-xl md:grid-cols-4 xl:grid-cols-6 text-bluetext">
            <div className="col-span-2">
              <StaticImage
                alt="katsana"
                src="../../static/images/logos/techinasia.png"
                objectFit="contain"
                className="w-48 xl:w-64"
              />
              <p className="mt-6">
                Malaysia ranks 6th in the world in car theft. A startup wants to
                change that.
              </p>
            </div>
            <div className="col-span-2">
              <StaticImage
                alt="katsana"
                src="../../static/images/logos/malaymail.png"
                objectFit="contain"
                className=""
              />
              <p className="mt-6">
                KATSANA combines GPS with behavioral data to help motor
                insurers.
              </p>
            </div>
            <div className="col-span-2">
              <StaticImage
                alt="katsana"
                src="../../static/images/logos/theedge.png"
                objectFit="contain"
                className=""
              />
              <p className="mt-6">
                Insurance: A fairer way to price motor insurance premiums.
              </p>
            </div>
            <div className="col-span-2">
              <StaticImage
                alt="katsana"
                src="../../static/images/logos/nikkei.png"
                objectFit="contain"
                className=""
              />
              <p className="mt-2">
                New Asian companies find success with regional focus.
              </p>
            </div>
            <div className="col-span-2">
              <p className="mt-6">
                "What sets KATSANA apart from competitiors is its analytics..."
              </p>
            </div>
            <div className="col-span-2">
              <StaticImage
                alt="katsana"
                src="../../static/images/logos/thestar.png"
                objectFit="contain"
                className=""
              />
              <p className="mt-6">Data company gets RM4mil boost.</p>
            </div>
            <div className="col-span-2">
              <StaticImage
                alt="katsana"
                src="../../static/images/logos/thestar.png"
                objectFit="contain"
                className=""
              />
              <p className="mt-6">
                Technology to drive motor insurers earnings
              </p>
            </div>
            <div className="col-span-2">
              <StaticImage
                alt="katsana"
                src="../../static/images/logos/nst.png"
                objectFit="contain"
                className=""
              />
              <p className="mt-6">
                Maycap-backed KATSANA aims for 240,000 users by year-end in
                Malaysia and Indonesia
              </p>
            </div>
            <div className="col-span-2">
              <StaticImage
                alt="katsana"
                src="../../static/images/logos/dna.png"
                objectFit="contain"
                className=""
              />
              <p className="mt-6">
                Turning Malaysians into better and safer drivers the Katsana way
              </p>
            </div>
            <div className="col-span-2">
              <StaticImage
                alt="katsana"
                src="../../static/images/logos/vulcan.png"
                objectFit="contain"
                className=""
              />
              <p className="mt-6">
                Katsana: 3 Years On, From Tracking Lost Cars To Dominating
                Corporate Fleets In Malaysia
              </p>
            </div>
            <div className="col-span-2">
              <StaticImage
                alt="katsana"
                src="../../static/images/logos/theedge.png"
                objectFit="contain"
                className=""
              />
              <p className="mt-6">
                Katsana partners insurers to boost usage-based insurance
                adoption
              </p>
            </div>
            <div className="col-span-2">
              <StaticImage
                alt="katsana"
                src="../../static/images/logos/fintechmalaysia.png"
                objectFit="contain"
                className=""
              />
              <p className="mt-6">
                KATSANA's Drivemark Moves Towards Introducing Usage Based
                Insurance
              </p>
            </div>
          </div>
        </div>

        <div className="relative bg-gradient-to-b from-[hsla(202,28%,81%,1)] to-[hsla(204,26%,93%,1)]">
          <div className="max-w-6xl px-8 py-12 mx-auto xl:px-0">
            <div className="absolute top-0 left-0 w-full">
              <div
                className={
                  Styles.tilt +
                  " after:bg-[hsla(202,28%,81%,1)] z-10 m-[-1px] after:h-[4vh] md:after:h-[10vh] z-10 "
                }
              ></div>
            </div>

            <h3 className="text-3xl font-bold text-blue-700 md:text-4xl">
              Our Journey
            </h3>
            <div className="grid grid-cols-3 gap-6 mt-12 md:grid-cols-6 xl:grid-cols-12 xl:gap-x-6 xl:gap-y-12">
              {/* Row 1  */}
              <div className="col-span-3 bg-white rounded">
                <div className="relative z-10 w-full h-48 overflow-hidden rounded-tl rounded-tr">
                  <StaticImage
                    alt="katsana"
                    src="../../static/images/photos/superb2014.jpg"
                    objectFit="cover"
                    className="!absolute !inset-0 rounded-tl rounded-tr"
                  />
                </div>

                <div className="p-6 text-sm text-bluebg">
                  <h5 className="font-medium">2014</h5>
                  <p>
                    Won the first ever SUPERB program organized by TERAJU, a
                    unit under the Prime Minister's Department.
                  </p>
                  <p className="mt-6">
                    Award presented by the Prime Minister of Malaysia, Datuk
                    Seri Najib Razak
                  </p>
                </div>
              </div>
              <div className="col-span-3 bg-white rounded">
                <div className="relative z-10 w-full h-48 overflow-hidden rounded-tl rounded-tr">
                  <StaticImage
                    alt="katsana"
                    src="../../static/images/photos/journey2.jpg"
                    objectFit="cover"
                    className="!absolute !inset-0 rounded-tl rounded-tr"
                  />
                </div>
                <div className="p-6 text-sm text-bluebg">
                  <h5 className="font-medium">2014</h5>
                  <p>
                    Recovered first ever stolen car, a Honda Civic Type-R FD2R
                    in less than 60 minutes.
                  </p>
                  <p className="mt-6">
                    The authority managed to catch the thief and recover several
                    other stolen cars.
                  </p>
                </div>
              </div>
              <div className="col-span-3 bg-white rounded">
                <div className="relative z-10 w-full h-48 overflow-hidden rounded-tl rounded-tr">
                  <StaticImage
                    alt="katsana"
                    src="../../static/images/photos/journey3.jpg"
                    objectFit="cover"
                    className="!absolute !inset-0 rounded-tl rounded-tr"
                  />
                </div>
                <div className="p-6 text-sm text-bluebg">
                  <h5 className="font-medium">2014</h5>
                  <p>
                    Expanded into enterprise Fleet Management market after
                    seeing strong demand from customers.
                  </p>
                  <p className="mt-6">
                    Developed driver scoring system called DriveMark.
                  </p>
                </div>
              </div>
              <div className="col-span-3 bg-white rounded">
                <div className="relative z-10 w-full h-48 overflow-hidden rounded-tl rounded-tr">
                  <StaticImage
                    alt="katsana"
                    src="../../static/images/photos/journey4.jpg"
                    objectFit="cover"
                    className="!absolute !inset-0 rounded-tl rounded-tr"
                  />
                </div>
                <div className="p-6 text-sm text-bluebg">
                  <h5 className="font-medium">2016</h5>
                  <p>
                    Received venture investment of RM4.0 million (later
                    increased to RM6.5 million) by Axiata Digital Innovation
                    Fund, managed by Intres Capital.
                  </p>
                  <p className="mt-6">
                    Entered usage-based insurance market via DriveMark.
                  </p>
                </div>
              </div>
              {/* Row 2  */}
              <div className="col-span-3 bg-white rounded">
                <div className="relative z-10 w-full h-48 overflow-hidden rounded-tl rounded-tr">
                  <StaticImage
                    alt="katsana"
                    src="../../static/images/photos/journey5.jpg"
                    objectFit="cover"
                    className="!absolute !inset-0 rounded-tl rounded-tr"
                  />
                </div>
                <div className="p-6 text-sm text-bluebg">
                  <h5 className="font-medium">2017</h5>
                  <p>
                    Signed MoUs with Allianz Malaysia, Etiqa Insurance, Etiqa
                    Takaful and Axiata Business to explore and introduce
                    usage-based insurance motor products in Malaysia.
                  </p>
                </div>
              </div>
              <div className="col-span-3 bg-white rounded">
                <div className="relative z-10 w-full h-48 overflow-hidden rounded-tl rounded-tr">
                  <StaticImage
                    alt="katsana"
                    src="../../static/images/photos/journey6.jpg"
                    objectFit="cover"
                    className="!absolute !inset-0 rounded-tl rounded-tr"
                  />
                </div>
                <div className="p-6 text-sm text-bluebg">
                  <h5 className="font-medium">2017</h5>
                  <p>
                    Selected as one of seven high potential startups globally to
                    participate in Singapore SmartCity and Nissan Infiniti Smart
                    Mobility Challenge.
                  </p>
                  <p className="mt-6">
                    Programme held in Singapore and Hong Kong for 3 months.
                  </p>
                </div>
              </div>
              <div className="col-span-3 bg-white rounded">
                <div className="relative z-10 w-full h-48 overflow-hidden rounded-tl rounded-tr">
                  <StaticImage
                    alt="katsana"
                    src="../../static/images/photos/journey7.jpg"
                    objectFit="cover"
                    className="!absolute !inset-0 rounded-tl rounded-tr"
                  />
                </div>
                <div className="p-6 text-sm text-bluebg">
                  <h5 className="font-medium">2017</h5>
                  <p>
                    Collaborated with Proton R&D Department to integrate KATSANA
                    telematics hardware into new Proton models.
                  </p>
                  <p className="mt-6">
                    KATSANA held various technology sharing classes with Proton
                    on new mobility technology & ideas.
                  </p>
                </div>
              </div>
              <div className="col-span-3 bg-white rounded">
                <div className="relative z-10 w-full h-48 overflow-hidden rounded-tl rounded-tr">
                  <StaticImage
                    alt="katsana"
                    src="../../static/images/photos/journey8.jpg"
                    objectFit="cover"
                    className="!absolute !inset-0 rounded-tl rounded-tr"
                  />
                </div>
                <div className="p-6 text-sm text-bluebg">
                  <h5 className="font-medium">2017</h5>
                  <p>
                    Malaysian representative for the Asian Entrepreneurship
                    Award 2017 at Kashiwano-ha City, Tokyo, Japan.
                  </p>
                </div>
              </div>
              {/* Row 3  */}
              <div className="col-span-3 bg-white rounded">
                <div className="relative z-10 w-full h-48 overflow-hidden rounded-tl rounded-tr">
                  <StaticImage
                    alt="katsana"
                    src="../../static/images/photos/journey9.jpg"
                    objectFit="cover"
                    className="!absolute !inset-0 rounded-tl rounded-tr"
                  />
                </div>
                <div className="p-6 text-sm text-bluebg">
                  <h5 className="font-medium">2018</h5>
                  <p>A year of conferences.</p>
                  <p className="mt-6">
                    <ul>
                      <li>
                        - Asia Conference on Big Data and Analytics for
                        Insurance, Singapore
                      </li>
                      <li>- Digital Finance, Kuala Lumpur</li>
                      <li>
                        - Asia Motor Insurance and Claims Management Conference,
                        Vietnam
                      </li>
                      <li>- Takaful Innovation Summit, KL</li>
                    </ul>
                  </p>
                </div>
              </div>
              <div className="col-span-3 bg-white rounded">
                <div className="relative z-10 w-full h-48 overflow-hidden rounded-tl rounded-tr">
                  <StaticImage
                    alt="katsana"
                    src="../../static/images/photos/journey10.jpg"
                    objectFit="cover"
                    className="!absolute !inset-0 rounded-tl rounded-tr"
                  />
                </div>
                <div className="p-6 text-sm text-bluebg">
                  <h5 className="font-medium">2018</h5>
                  <p>
                    Proud main sponsor of the Conference of ASEAN Road Safety
                    2018, launched by the Minister of Transport, YB Anthony
                    Loke.
                  </p>
                  <p className="mt-6">
                    The conference bring organizations with an interest in the
                    future of road safety betterment.
                  </p>
                </div>
              </div>
              <div className="col-span-3 bg-white rounded">
                <div className="relative z-10 h-56 rounded-tl rounded-tr">
                  <StaticImage
                    alt="katsana"
                    src="../../static/images/photos/journey11.jpg"
                    objectFit="cover"
                    className="!absolute !inset-0 rounded-tl rounded-tr"
                  />
                </div>
                <div className="p-6 text-sm text-bluebg">
                  <h5 className="font-medium">2019</h5>
                  <p>
                    Launched DriveMark version 2.0 after a year of User
                    Interaction Study and development from ground up.
                  </p>
                </div>
              </div>
              <div className="col-span-3 bg-white rounded">
                <div className="relative z-10 w-full h-48 overflow-hidden rounded-tl rounded-tr">
                  <StaticImage
                    alt="katsana"
                    src="../../static/images/photos/journey12.jpg"
                    objectFit="cover"
                    className="!absolute !inset-0 rounded-tl rounded-tr"
                  />
                </div>
                <div className="p-6 text-sm text-bluebg">
                  <h5 className="font-medium">2019</h5>
                  <p>
                    Contributed in CEO SPARKZ program, launched by YB Yeo Bee
                    Yin, the Minister of Energy, Science, Technology,
                    Environment and Climate Change.
                  </p>
                </div>
              </div>
              {/* Row 4  */}
              <div className="col-span-3 bg-white rounded">
                <div className="relative z-10 w-full h-48 overflow-hidden rounded-tl rounded-tr">
                  <StaticImage
                    alt="katsana"
                    src="../../static/images/photos/journey13.jpg"
                    objectFit="cover"
                    className="!absolute !inset-0 rounded-tl rounded-tr"
                  />
                </div>
                <div className="p-6 text-sm text-bluebg">
                  <h5 className="font-medium">2019</h5>
                  <p>
                    Partnered with PETRONAS Dagangan, PLUS, Touch n’ Go and
                    MIROS to launch the Chinese New Year Safest Driver
                    Challenge.
                  </p>
                  <p className="mt-6">
                    Program attracted over 60,000 participants and was launched
                    by Minister of Transport.
                  </p>
                </div>
              </div>
              <div className="relative flex items-end col-span-3 bg-white rounded">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/photos/journey14.jpg"
                  objectFit="cover"
                  className="!absolute inset-0 z-0 rounded"
                />
                <div className="relative z-10 p-6 mb-6 text-sm text-white">
                  <h5 className="font-medium">2019</h5>
                  <p>
                    Established KATSANA FLeet Services to focus sales and
                    marketing of enterprise fleet management and integrated
                    industrial sensors solutions.
                  </p>
                </div>
              </div>
              <div className="col-span-3 bg-white rounded">
                <div className="relative z-10 w-full h-48 overflow-hidden rounded-tl rounded-tr">
                  <StaticImage
                    alt="katsana"
                    src="../../static/images/photos/journey15.jpg"
                    objectFit="cover"
                    className="!absolute !inset-0 rounded-tl rounded-tr"
                  />
                </div>
                <div className="p-6 text-sm text-bluebg">
                  <h5 className="font-medium">2019</h5>
                  <p>
                    Participated in SMMT Future Mobility Challenge 2019, London.
                  </p>
                  <p className="mt-6">
                    Presented DriveMark as an in-car loyalty & reward program to
                    one of the largest auto companies in the world.
                  </p>
                </div>
              </div>
              <div className="col-span-3 bg-white rounded">
                <div className="relative z-10 w-full h-48 overflow-hidden rounded-tl rounded-tr">
                  <StaticImage
                    alt="katsana"
                    src="../../static/images/photos/journey16.jpg"
                    objectFit="cover"
                    className="!absolute !inset-0 rounded-tl rounded-tr"
                  />
                </div>
                <div className="p-6 text-sm text-bluebg">
                  <h5 className="font-medium">2019</h5>
                  <p>
                    Completed the first major industrial sensor solution project
                    at one of the biggest palm oil plantations in Malaysia.
                  </p>
                </div>
              </div>
              {/* Row 5  */}
              <div className="relative flex items-end col-span-3 bg-white rounded">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/photos/journey17.jpg"
                  objectFit="cover"
                  className="!absolute inset-0 z-0 rounded"
                />
                <div className="relative z-10 p-6 text-sm text-white">
                  <h5 className="font-medium">2019</h5>
                  <p>Entered Japanese domestic market.</p>
                </div>
              </div>
              <div className="relative flex items-end col-span-3 pt-12 bg-white rounded">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/photos/journey18.jpg"
                  objectFit="cover"
                  className="!absolute inset-0 rounded"
                />

                <div className="relative z-10 p-6 text-sm text-white">
                  <h5 className="font-medium">2020</h5>
                  <p>
                    Made studies of national behavior pattern and crowd movement
                    pre and post Covid pandemic in relation to Malaysia
                    government’s lockdown announcements and measures.
                  </p>
                </div>
              </div>
              <div className="col-span-3 bg-white rounded">
                <div className="relative z-10 w-full h-48 overflow-hidden rounded-tl rounded-tr">
                  <StaticImage
                    alt="katsana"
                    src="../../static/images/photos/journey19.jpg"
                    objectFit="cover"
                    className="!absolute !inset-0 rounded-tl rounded-tr"
                  />
                </div>
                <div className="p-6 text-sm text-bluebg">
                  <h5 className="font-medium">2020</h5>
                  <p>
                    Collaborated with Malaysian Institute of Road Safety
                    Research (MIROS) and International Medical University (IMU)
                    on road safety initiatives.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Cta />
    </Layout>
  );
};

export default AboutUs;
